import moment from 'moment';
const STORAGE_KEY = 'relational-nps-search';

export default {
    namespaced: true,

    state: () => ({        
        participated: null,
        show_after_months: 3,
        contract_date_start: null,
        current_contract_date: null,
    }),

    mutations: {        
        setParticipatedInSearch(state, value) {
            state.participated = value;
            localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
        },

        setContractDateStart(state, contractDateStart) {
            state.contract_date_start = contractDateStart;
            localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
        },

        setCurrentContractDate(state) {
            if(state.contract_date_start) {
                const now = moment();
                const contractDateStart = moment(state.contract_date_start);
                state.current_contract_date = now.diff(contractDateStart, 'months');            
            }            
        },
    },

    getters: {
        canDisplayTheSearch(state) {
            return (!state.participated && state.current_contract_date > state.show_after_months);
        },
    },

    actions: {
        async loadRelationalNpsData({ state, commit, dispatch }) {
            let relationalNpsData = localStorage.getItem(STORAGE_KEY);

            if(relationalNpsData) {                
                relationalNpsData = JSON.parse(relationalNpsData);
                state.participated = relationalNpsData.participated;
                state.contract_date_start = relationalNpsData.contract_date_start;
                commit('setCurrentContractDate');
            }

            if(state.participated == null) {                
                await dispatch('getParticipatedState');
            }
        },

        async clearRelationalNpsData() {
            localStorage.removeItem(STORAGE_KEY);
        },   
        
        async getParticipatedState({ commit }) {
            const { data } = await axios.get('relational-nps-search/has-participated');
            commit('setParticipatedInSearch', data.data.participated);
        }
    }
}