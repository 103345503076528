export default {
    namespaced: true,
    state: () => ({
        route: {
            page: 1,
            totalPage: 15,
            order: 'orderBy[name]=ASC'
        },
        formFilter: {
            show: false,
            search: null,
            company_id: null,
            cbo_id: null,
            class: null,
            sector_id: null
        }
    }),

    mutations: {
        setRouteDefault(state) {
            state.route = {
                page: 1,
                totalPage: 15,
                order: 'orderBy[name]=ASC'
            };

            state.formFilter = {
                show: false,
                search: null,
                company_id: null,
                cbo_id: null,
                class: null,
                sector_id: null
            };
        }
    },

    getters: {
        countDescriptionPositions(state) {
            const form = { ...state.formFilter };
            delete form.search;
            delete form.show;

            return Object.keys(form).filter(key => form[key] !== null).length;
        },

        getFilter(state) {
            let endpoint = ``;
            if (state.formFilter.company_id) {
                endpoint += `&where[company_id]=${state.formFilter.company_id}`;
            }
            
            if (state.formFilter.class) {
                endpoint += `&where[class]=${state.formFilter.class}`;
            }

            if (state.formFilter.search) {
                endpoint += `&whereLike[name]=${state.formFilter.search}`;
            }

            if (state.formFilter.cbo_id) {
                endpoint += `&where[cbo_id]=${state.formFilter.cbo_id}`;
            }

            if (state.formFilter.sector_id) {
                endpoint += `&where[sector_id]=${state.formFilter.sector_id}`;
            }

            return endpoint;
        }
    }
};
