import initTypebotNPSTransationalByResponse from "./initTypebotNPSTransationalByResponse";

const interceptors = [
    initTypebotNPSTransationalByResponse,
]

export default function (response, store) {
    interceptors.forEach(interceptor => {
        if(typeof interceptor === 'function') {
            interceptor(response, store);
        }
    })
}