import moment from 'moment';

export default {
    namespaced: true,

    state: () => ({
        visible: false,
        show_after_days: 15,
        last_search_performed_date: null,
    }),

    mutations: {
        show(state) {            
            state.visible = true;
        },
        hide(state) {
            state.visible = false;
        },
        setLastSearchPerformedDate(state, lastSearchPerformedDate) {
            state.last_search_performed_date = lastSearchPerformedDate;
            localStorage.setItem('last_search_performed_date', lastSearchPerformedDate);
        },
    },

    getters: {
        isVisible(state) {
            return state.visible;
        },
        canDisplayTheSearch(state) {
            if(!state.last_search_performed_date) return true;
            const now = moment();            
            const lastSearchPerformedDate = moment(state.last_search_performed_date);        
            return now.diff(lastSearchPerformedDate, 'days') > state.show_after_days;
        },
    },

    actions: {
        async getLastSearchPerformed({ commit }) {
            let lastSearchPerformedDate = localStorage.getItem('last_search_performed_date');

            if(!lastSearchPerformedDate || lastSearchPerformedDate == 'undefined') {                
                const { data } = await axios.get('/transactional-nps-search/last-search-performed');
                lastSearchPerformedDate = data.data?.lastSearchPerformed?.created_at;
            }                    
            commit('setLastSearchPerformedDate', lastSearchPerformedDate);
        },

        async removeLastSearchPerformedDate() {
            localStorage.removeItem('last_search_performed_date');
        }
    }
}
