<template>
    <!--  BEGIN SIDEBAR  -->
    <div class='sidebar-wrapper sidebar-theme'>
        <nav ref='menu' id='sidebar'>
            <div class='shadow-bottom'></div>

            <perfect-scrollbar
                class='list-unstyled menu-categories'
                tag='ul'
                :options='{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }'>

                <li class='menu'>
                    <router-link :to='{name: "portal.home"}' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-chart-pie' />
                            <span>Dashboard</span>
                        </div>
                    </router-link>
                </li>

                <li class='menu'>
                    <router-link :to='{name: "portal.position-description"}' class='dropdown-toggle'
                                 @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-comments' />
                            <span>Chat de Descrição</span>
                        </div>
                    </router-link>
                </li>

                <li class='menu' v-can:analyze-description-view-portal>
                    <router-link :to='{name: "portal.approval-description"}' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='fa-solid fa-clipboard' />
                            <span>Descrições</span>
                        </div>
                    </router-link>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    const store = useStore();

    onMounted(async () => {
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>
