export default {
    namespaced: true,
    state: () => ({
        route: {
            page: 1,
            totalPage: 15,
            order: 'orderBy[start_date]=ASC'
        },
        formFilter: {
            show: false,
            search: null,
        }
    }),

    mutations: {
        setRouteDefault(state) {
            state.route = {
                page: 1,
                totalPage: 15,
                order: 'orderBy[start_date]=DESC'
            };

            state.formFilter = {
                show: false,
                search: null,
            };
        }
    },

    getters: {
        getFilter(state) {
            let endpoint = ``;
            if (state.formFilter.search) {
                endpoint += `&whereLike[name]=${state.formFilter.search}`;
            }

            return endpoint;
        }
    }
};
