<script setup>
    import { onBeforeMount } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();

    function addTypeBot(token) {
        const url_api = process.env.VUE_APP_HOST_API + 'relational-nps-search';
        const url_avatar = process.env.VUE_APP_TYPEBOT_HOST_AVATAR;

        Typebot.initBubble({
            id: 'relational-nps-search',
            typebot: 'nps-survey-i66nfqn',
            apiHost: `https://typechat.deppes.com`,
            prefilledVariables: {
                token: token,
                url_api: url_api
            },
            previewMessage: {
                message: 'Queremos ouvir você!',
                avatarUrl: url_avatar
            },
            theme: {
                button: { backgroundColor: '#0042DA' },
                chatWindow: { backgroundColor: '#fff' }
            },
            onEnd() {
                setTimeout(() => Typebot.close(), 2000);
                setTimeout(() => removeTypeBot(), 4000);
                store.dispatch('relationalNps/getParticipatedState');
            }
        });
        Typebot.showPreviewMessage();
    }

    function removeTypeBot() {
        const typebotElement = document.getElementById('relational-nps-search');
        if (typebotElement) typebotElement.parentNode.removeChild(typebotElement);
    }

    onBeforeMount(async () => {
        if(store.state.user?.user?.permissions && !store.state.user?.user?.permissions.includes('is-support')) {
            await store.dispatch('relationalNps/loadRelationalNpsData');
            const canDisplayTheSearch = store.getters['relationalNps/canDisplayTheSearch'];
            if (canDisplayTheSearch) {
                addTypeBot(store.state.user.token);
            }   
        }
    });
</script>
<template></template>
