export default {
    namespaced: true,
    state: () => ({
        total_page: 15,
        current_page: 1,
        description_positions_order: ``,
        description_positions: {
            show: false,
            search: null,
            company_id: null,
            status_description: null,
            sector_id: null,
            only_free: false,
        }
    }),

    mutations: {
        setRouteDefault(state) {
            state.description_positions = {
                show: false,
                search: null,
                company_id: null,
                status_description: null,
                sector_id: null
            };

            state.total_page = 15;
            state.current_page = 1;
            state.description_positions_order = '';
        }
    },

    getters: {
        countDescriptionPositions(state) {
            const form = { ...state.description_positions };
            delete form.search;
            delete form.show;

            return Object.keys(form).filter(key => form[key] !== null).length;
        },

        getDescriptionPositions(state) {
            let endpoint = ``;
            if (state.description_positions.search) {
                endpoint += `&search=${state.description_positions.search}`;
            }

            if (state.description_positions.company_id) {
                endpoint += `&company_id=${state.description_positions.company_id}`;
            }

            if (state.description_positions.sector_id) {
                endpoint += `&sector_id=${state.description_positions.sector_id}`;
            }

            if (state.description_positions.status_description) {
                endpoint += `&status_description=${state.description_positions.status_description}`;
            }

            return endpoint;
        }
    }
};
