import { useStore } from "vuex";
import { computed, watch } from "vue";

/**
 * Inicia o typebot com base na resposta.
 * @param {*} response 
 */
export default function (response, store) 
{
    const isAuth = store.getters['user/isAuth'];

    if(isAuth) {
        const method = response.config.method;
        const contentType = response.headers['content-type'];

        const supportedMethods = [
            'post', 'put', 'delete'
        ];

        const supportedContentType = [
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ];

        if(supportedMethods.includes(method)) {
            store.commit('transactionalNps/show');
            
        }else if (supportedContentType.includes(contentType)) {
            store.commit('transactionalNps/show');
        }
    }    
}
