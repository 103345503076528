<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">        
        <component v-bind:is="layout"></component>
    </div>
</template>

<script setup>
    import '@/assets/sass/app.scss';
    import { computed } from 'vue';
    import { useMeta } from '@/composables/use-meta';
    import { useStore } from 'vuex';

    useMeta({ title: 'Deppes' });

    const store = useStore();
    const layout = computed(() => {
        return store.getters.layout;
    });
    
</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import portalLayout from './layouts/portal-layout.vue';

    export default {
        components: {
            app: appLayout,
            auth: authLayout,
            portal: portalLayout,
        },
    };
</script>
