export default {
    namespaced: true,
    state: () => ({
        total_page: 15,
        current_page: 1,
        description_positions_order: `orderBy[name]=ASC`,
        description_positions: {
            show: false,
            name: null,
            company_id: null,
            cbo_id: null,
            class: null,
            sector_id: null,
            only_free: false
        }
    }),

    mutations: {
        setRouteDefault(state) {
            state.description_positions = {
                show: false,
                name: null,
                company_id: null,
                cbo_id: null,
                class: null,
                sector_id: null
            };

            state.total_page = 15;
            state.current_page = 1;
            state.description_positions_order = 'orderBy[name]=ASC';
        }
    },
    
    getters: {
        countDescriptionPositions(state) {
            const form = { ...state.description_positions };
            delete form.name;
            delete form.show;

            return Object.keys(form).filter(key => form[key] !== null).length;
        },

        getDescriptionPositions(state) {
            let endpoint = ``;
            if (state.description_positions.name) {
                endpoint += `&whereLike[name]=${state.description_positions.name}`;
            }

            if (state.description_positions.company_id) {
                endpoint += `&where[company_id]=${state.description_positions.company_id}`;
            }

            if (state.description_positions.cbo_id) {
                endpoint += `&where[cbo_id]=${state.description_positions.cbo_id}`;
            }

            if (state.description_positions.sector_id) {
                endpoint += `&where[sector_id]=${state.description_positions.sector_id}`;
            }

            if (state.description_positions.class) {
                endpoint += `&where[class]=${state.description_positions.class}`;
            }

            return endpoint;
        }
    }
};
