<template>
    <!-- BEGIN FOOTER -->
    <div class='footer-wrapper'>
        <div class='footer-section f-section-1'>
            <p class=''>Copyright © 2023 <a target='_blank' href='https://arrangic.com'>Deppes</a>, Todos os direitos
                reservados.</p>
        </div>
        <div class='footer-section f-section-2'>

        </div>
    </div>
    <!-- END FOOTER -->
</template>
